<template>
  <div class="message-con">
    <Search background="#4184f7" :value='title' shape="round" @input="searchShopList">
    </Search>
    <div class="sign_integral">
      <div class="sign_integral_top">
        <div class="integral">
          <span
            v-text="parseInt(myInfo.wallet.point)"
            v-if="myInfo.wallet.point"
          ></span>
          <span v-else>0</span>
          <span class="fonts">积分</span>
        </div>
        <div>
          <router-link class="list-icon-link" to="integral?type=1">
            <span>兑换记录</span>
            <span class="iconfont iconxiaofeijilu"></span>
          </router-link>
        </div>
      </div>
      <div class="sign_cont">
        <div class="sign_cont_title">一 积/分/范/围 一</div>
        <div class="sign-com">
          <div 
            :class="[nowIdx === index ? 'active sign_cont_item':'sign_cont_item']" 
            v-for="(item, index) in filterArr" 
            @click="endSearchCon(item, index)" 
            :key="index"
          >
            <template v-if="index < 5">
              <span>{{item.first_num}}</span>
              <span>-</span>
              <span>{{item.end_num}}</span>
            </template>
            <template v-else>
              <span>>=5000积分</span>
            </template>
          </div>
        </div>
        <!-- <div class="sign_cont_operation">
          <span>已签到{{ sign_info.orderday }}天</span>
          <Button
            round
            type="info"
            size="small"
            color="#FF914B"
            @click="userSignIn(sign_info.orderday)"
            >签到领取</Button
          >
        </div>
        <div class="sign_step">
          <div
            class="sign_step_item"
            v-for="(item, index) in checkInArr"
            :key="index"
          >
            <div class="_item" :class="item.status ? '_item_on' : ''">
              {{ item.day }}
            </div>
            <div
              class="line"
              v-if="item.day != 7"
              :class="item.status ? '_item_on' : ''"
            ></div>
            <div class="hint" :class="item.status ? 'color_on' : ''">
              <span>+</span>
              <span v-text="parseInt(item.credit)"></span>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="list-con">
      <Tabs
        class="tabs-con"
        v-model="active_tab"
        sticky
        swipeable
        v-if="custom_status"
        @click="getCateGoods"
      >
        <Tab
          v-for="(item, index) in custom_arr"
          :key="index"
          :title="item.name"
          :name="item.id"
        >
          <List
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="load_list"
          >
            <IntegralList :listArr="listArr"></IntegralList>
          </List>
        </Tab>
      </Tabs>
      <List
        v-else
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="load_list"
      >
        <IntegralList :listArr="listArr"></IntegralList>
      </List>
    </div>
  </div>
</template>

<script>
const filterArr = [
  {
    first_num: 0,
    end_num: 400,
  },
  {
    first_num: 400,
    end_num: 800,
  },
  {
    first_num: 800,
    end_num: 1500,
  },
  {
    first_num: 1500,
    end_num: 3000,
  },
  {
    first_num: 3000,
    end_num: 5000,
  },
  {
    first_num: 6000,
    end_num: '----',
  },
];

import IntegralList from "./integralitem";
import CreditShop from "@/api/creditshop";
import Config from "@/api/config";
import Utils from "@/lin/utils/util";
import { List, Tab, Tabs, Search } from "vant";
export default {
  components: {
    List,
    IntegralList,
    Tab,
    Tabs,
    Search,
  },
  data() {
    return {
      title: '',
      filterArr: filterArr,
      nowItem: {},
      active_tab: "",
      custom_arr: [
        {
          name: "平台商品",
          id: '-1',
        },
        {
          name: "全部",
          id: "",
        },
      ],
      nowIdx: -1,
      custom_status: false,
      message: "",
      loading: true,
      input_loading: false,
      finished: false,
      listArr: [],
      page: 1,
      myInfo: {
        wallet: {},
      },
      checkInArr: [],
      sign_info: {
        orderday: 0,
        credit: 0,
      },
      sign_status: false,
      point_shop_status: true
    };
  },
  async created() {
    this.isPointStatus();
    this.myInfo = await this.getUserInfo();
    this.getCheckList();
    this.getCustomList();
  },
  methods: {
    async getCateGoods() {
      if (this.active_tab == -1) {
        let app_id = localStorage.getItem('app_id')
        let app_token = localStorage.getItem('access_token')
        window.location.href = `https://hyk.zltc.cc?app_token=${app_token}&app_id=${app_id}`
      }
      this.listArr = [];
      this.page = 1;
      this.loading = true;
      this.finished = false;
      await this.load_list();
    },
    // 搜索栏目
    searchShopList: Utils.debounce(async function(e) {
      var _this = this;
      _this.$toast.loading({
        message: "搜索中...",
        forbidClick: true,
      });
      _this.page = 1;
      _this.listArr = [];
      await _this.load_list(e);
      _this.$toast.clear();
    }, 500),
    // 是否使用新的积分商城
    async isPointStatus() {
      const res = await Config.getAppconfigButton()
      if (res.code === 200) {
        if (res.data.status == 1) {
          this.point_shop_status = true;
        } else {
          // 旧的
          this.custom_arr.splice(0, 1);
        }
      }
    },
    async getCustomList() {
      try {
        const res = await CreditShop.getCustomList();
        if (res.code == 200) {
          this.custom_arr = [
            ...this.custom_arr, 
            ...res.data,
            {
              name: "金钱购买",
              id: '3',
            },
            {
              name: "积分加钱",
              id: "2",
            },
            {
              name: "积分兑换",
              id: '1',
            }
          ]; 
          if (res.data.length > 0) {
            this.custom_status = true;
          } else {
            this.loading = false;
          }
          await this.load_list();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCheckList() {
      try {
        const res = await CreditShop.getSignCycle();
        if (res.code == 200) {
          if (res.data.result.length > 0) {
            this.checkInArr = res.data.result;
            if (res.data.sign_info == "" || res.data.sign_info == null) {
              throw "暂无签到内容";
            } else {
              this.sign_info = res.data.sign_info;
              console.log(this.sign_info);
            }
          } else {
            throw "无数据...";
          }
        } else {
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取哪一天签到
    setDay() {
      var day = 1;
      try {
        var el = document.getElementsByClassName("_item _item_on");
        if (el.length > 0) {
          day = el.length + 1;
        } else {
          day = 1;
        }
      } catch (err) {
        day = 1;
        console.log(err);
      }
      return day;
    },
    // 签到
    async userSignIn(item) {
      let day = this.setDay();
      let credit = this.checkInArr[item].credit;
      try {
        const res = await CreditShop.userCheckIn(day, credit);
        if (res.code == 200) {
          this.$toast(res.msg);
          // 更新
          this.getCheckList();
        } else {
          throw res.msg;
        }
      } catch (error) {
        this.$toast(error || "网络错误");
      }
    },
    goConvert() {
      this.$router.push({
        path: "/integral",
        query: {
          type: 1,
        },
      });
    },
    // 区间搜索
    async endSearchCon(item, index) {
      this.page = 1;
      this.listArr = [];
      this.loading = true;
      this.finished = false;
      if (index === this.nowIdx) {
        this.nowIdx = -1;
        return this.load_list(this.title, '', '');
      }
      this.nowIdx = index;
      this.nowItem = item;
      if (index === 5) item.first_num = 5000, item.end_num = 10000000000;
      this.load_list(this.title, item.first_num, item.end_num);
    },
    // 判断购买方式参数
    getBuymethod() {
      if (this.active_tab == "") return "";
      if (this.active_tab > 0 && this.active_tab < 4) return this.active_tab;
      else return "";
    },
    // 商品列表
    async load_list(title, credit_start = this.nowItem.first_num, credit_end = this.nowItem.end_num) {
      try {
        const res = await CreditShop.getCreditshopList(
          this.page++,
          this.active_tab,
          title,
          credit_start,
          credit_end,
          this.getBuymethod()
        );
        if (res.code == 200) {
          if (res.data.result.length > 0) {
            res.data.result.forEach((item) => {
              this.listArr.push(item);
            });
            this.loading = false;
            if (this.listArr.length == res.data.total_num) {
              this.finished = true;
            }
          } else {
            this.loading = false;
            this.finished = true;
          }
        } else {
          throw res.msg;
        }
      } catch (error) {
        this.loading = false;
        this.finished = true;
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$bgcolor: rgba(244, 244, 244, 1);
.message-con {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  background: $bgcolor;
  .van-list__finished-text {
    width: 90%;
    margin: 0 auto;
  }
  .message-title {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    display: flex;
    font-size: 14px;
    position: fixed;
    left: 0;
    top: 46px;
    background: #ffffff;
    align-items: center;
    justify-content: space-between;
    .title-right {
      color: rgba(255, 106, 69, 1);
    }
  }
  .sign_integral {
    height: 220px;
    background-image: url("../../../../public/static/images/home/integralHeader.png");
    background-repeat: no-repeat;
    background-size: contain;
    .sign_integral_top {
      min-height: 120px;
      text-align: center;
      color: #fff;
      .integral {
        padding: 15px 0 10px;
        font-size: 38px;
        font-weight: 400;
        .fonts {
          font-size: 14px;
          font-weight: none;
          margin-left: 3px;
        }
      }
      .list-icon-link {
        color: #fff;
        font-size: 16px;
        .iconxiaofeijilu {
          margin-left: 10px;
        }
      }
    }
    .sign_cont {
      width: 100%;
      margin: 0 auto;
      height: auto;
      background: rgba(255, 255, 255, 1);
      margin-bottom: 20px;
      // border-radius: 8px;
      // padding: 2px 10px;
      > .sign_cont_title {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        padding-top: 10px;
        letter-spacing: 6px;
      }
      > .sign-com {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
        // margin-left: -20px;
        justify-content: space-around;
        // justify-content: space-between;
        > .sign_cont_item {
          width: 110px;
          color: #ffffff;
          font-size: 13px;
          margin-top: 10px;
          border-radius: 14px;
          text-align: center;
          padding: 5px 5px;
          background-color: #867f7b;
        }
        > .active {
          @extend .sign_cont_item;
          color: #fff;
          background-color: #ff914b;
        }
      }
    }
  }
  .sign_cont_operation {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  .hander {
    width: 92%;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    margin: 0 auto;
    line-height: 40px;
    padding-left: 10px;
    color: rgba(51, 51, 51, 1);
    background: #fff;
  }
  .list-con {
    width: 92%;
    height: auto;
    margin: 30px auto;
    .tabs-con {
      margin-top: 10px;
    }
  }
  .sign_step {
    padding: 0 10px;
    margin-top: 20px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: -30px;
    .sign_step_item {
      flex: 1;
      position: relative;
      ._item {
        width: 16px;
        height: 16px;
        background: #d8d8d8;
        border-radius: 50%;
        text-align: center;
        line-height: 16px;
        color: #fff;
        font-weight: 400;
        z-index: 2;
        position: relative;
      }
      .line {
        position: absolute;
        width: 100%;
        height: 2px;
        background: #d8d8d8;
        top: 7px;
      }
      .hint {
        margin-top: 4px;
        position: absolute;
        left: -5px;
        color: #d8d8d8;
      }
      ._item_on {
        background: #ff914b;
      }
      .color_on {
        color: #ff914b;
      }
    }
  }
}
</style>

<style lang="scss">
.message-con {
  .van-button--small {
    width: 120px;
  }
  .van-list {
    .integralitem {
      padding-bottom: 15px;
      border-radius: 8px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      .integral-child-img {
        width: 100%;
        height: 163px;
        margin: 0 auto;
      }
    }
    .integral-child-jifen {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}
</style>
