<template>
  <div class="integralitem" v-if="listArr.length">
    <div class="integral-child" 
      :class="['animation' + (idx+1), idx%2 == 0? 'on' : '']" 
      v-for="(item, idx) in listArr" :key="idx" 
      @click="goDeatil(item)"
    >
      <div class="integral-child-img">
        <img :src="item.pic" alt="">
      </div>
      <p class="integral-child-name">{{item.title}}</p>
      <div class="line">
        <div class="colorFont">
          <span v-if="item.buy_method == 1">{{item.credit}}积分</span>
          <span v-else-if="item.buy_method == 2">{{item.credit}}积分</span>
          <span v-else-if="item.buy_method == 3">{{item.money}}元</span>
        </div>
        <div>库存：<span>{{item.stock}}</span></div>
      </div>
      <div class="price-con" v-if="item.buy_method == 2">
        <span>金额:</span>
        <span>{{item.money}}元</span>
      </div>
      <!-- <div class="shellOut"></div> -->
      <img src="@static/vip/sellOut.png" alt="" class="shellOut" v-if="item.stock == 0">
    </div>
  </div>
</template>
<script>
export default {
  props: {
    listArr: {
      type: Array,
    }
  },
  data() {
    return {
      imgUrl: "https://img.iplaysoft.com/wp-content/uploads/2019/free-images/free_stock_photo.jpg"
    }
  },
  methods: {
    goDeatil(item) {
      this.$router.push('/integraldetail?id=' + item.id)
    }
  }
}
</script>
<style lang="scss" scoped>
.integralitem {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  .integral-child {
    position: relative;
    width: 49%;
    height: auto;
    display: flex;
    background: #ffffff;
    // border: 1px solid #dfdfdf;
    // border-bottom: 1px solid #dfdfdf;
    // border-radius: 6px;
    flex-direction: column;
    padding-bottom: 10px;
    margin-top: 10px;
    box-shadow: 0px 5px 10px 0px rgba(172,181,185,0.4);
    p {
      padding-left: 6px;
    }
    .integral-child-img {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .integral-child-name {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      padding-top: 4px;
    }
    .colorFont {
      color: #ff6a45;
      font-size: 14px;
      .point-con {
        display: flex;
        flex-direction: column;
      }
    }
    .line{
      display:flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 6px;
    }
    .price-con {
      width: 100%;
      height: 24px;
      align-items: center;
      padding: 0 8px;
      display: flex;
      justify-content: flex-end;
      span:nth-child(2) {
        margin-left: 5px;
        color: #ff6a45;
      }
    }
    .shellOut{
      position: absolute;
      width: 90%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  // .on:after {
  //   content: "";
  //   width: 1px;
  //   height: 95px;
  //   position: absolute;
  //   right: 0;
  //   background: #dfdfdf;
  //   top: 50%;
  //   margin-top: -46px;
  // }
}
</style>